import { EmptyState, GroupDot, ListSkeleton, Panel } from "@mixitone/components/Controls";
import RacketIcon from "@mixitone/components/icons/racket.svg";
import { ApplicationView } from "@mixitone/mvc";
import { ClubDetailsPageController } from "./ClubDetailsPageController";

export const GameHistory = ApplicationView(() => {
  const controller = ClubDetailsPageController.use();
  const { games, loadingGames } = controller.state;

  return (
    <Panel title="Game history" titleClassName="font-semibold" className="overflow-hidden">
      {loadingGames && <ListSkeleton itemCount={6} />}
      {games.length === 0 && !loadingGames && <EmptyState Icon={RacketIcon}>No games yet</EmptyState>}
      <table className="w-full max-w-full table-fixed">
        <colgroup>
          <col style={{ width: "15%" }} />
          <col style={{ width: "28%" }} />
          <col style={{ width: "28%" }} />
          <col style={{ width: "28%" }} />
        </colgroup>
        {games.map((session, idx) => (
          <tbody key={idx} className="mb-4">
            <tr>
              <td colSpan={3} className="pb-1 text-sm font-bold">
                {session.date}
              </td>
            </tr>
            {session.games.map((game, idx) => (
              <tr className="" key={idx}>
                <td className="text-sm font-semibold">Set {game.index + 1}</td>
                {game.players.map((player, idx) => (
                  <td key={idx}>
                    <div className="flex items-center gap-1">
                      <GroupDot group={player.group} size={12} className="flex-shrink-0" />
                      <div className="overflow-hidden whitespace-nowrap text-clip">{player.name}</div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td colSpan={3} className="h-4"></td>
            </tr>
          </tbody>
        ))}
      </table>
    </Panel>
  );
});
