import { Button, Input, LabelledField, bindControllerState } from "@mixitone/components";
import InfoIcon from "@mixitone/components/icons/info.svg";
import { ApplicationView } from "@mixitone/mvc";
import { withStateToggle } from "@mixitone/util/concerns";
import { User } from "@supabase/supabase-js";
import React from "react";
import { AuthController } from "../Components/Authenticated";
import { DashLayout } from "../Components/DashLayout";
import { ExtraAuthComponent } from "../Components/ExtraAuth";
import { MessageBanner } from "../Components/MessageBanner";
import { ObfuscatedInput } from "../Components/ObfuscatedInput";
import { PortalController } from "../lib/PortalController";
import portalFunctions from "../lib/portalFunctions";

interface State {
  loading: boolean;
  canUpdateProfile: boolean;
  user?: User;
  userData: {
    name: string;
    email: string;
    mobile: string;
  };
  emailChanged: boolean;
  saving: boolean;
  message: string | null;
  messageType: "success" | "error" | null;
}

class ProfilePageController extends PortalController<State> {
  get initialState(): State {
    return {
      loading: true,
      saving: false,
      canUpdateProfile: false,
      emailChanged: false,
      userData: { name: "", email: "", mobile: "" },
      message: null,
      messageType: null,
    };
  }

  async initialize() {
    this.state.canUpdateProfile = !this.get(AuthController).state.authedByTag;
    await this.loadUser();
  }

  async loadUser() {
    const response = await this.supabase.auth.getUser();
    this.state.user = response.data.user ?? undefined;

    this.state.userData = {
      name: this.state.user?.user_metadata.name ?? "",
      email: "",
      mobile: this.state.user?.user_metadata.mobile ?? "",
    };
    this.resetEmail();
    this.state.loading = false;
  }

  resetEmail() {
    let email = "";
    if (this.state.user?.email?.includes("mixitone.com")) {
      email = "";
    } else {
      email = this.state.user?.email ?? "";
    }
    this.state.userData.email = email;
    this.state.emailChanged = false;
  }

  setEmail(email: string) {
    if (email !== this.state.userData.email) {
      this.state.userData.email = email;
      this.state.emailChanged = true;
    }
  }

  @withStateToggle("saving")
  async actionSubmit() {
    if (!this.state.user || !this.state.canUpdateProfile) return;

    const updates: Parameters<typeof portalFunctions.updatePlayerProfile>[0] = {};
    updates.name = this.state.userData.name;
    updates.mobile = this.state.userData.mobile;
    if (this.state.emailChanged) {
      updates.email = this.state.userData.email;
    }

    try {
      await portalFunctions.updatePlayerProfile(updates);
      console.log("Profile updated successfully");
      this.state.message = "Profile updated successfully!";
      this.state.messageType = "success";
      this.state.emailChanged = false;
    } catch (error) {
      console.error("Error updating profile:", error);
      this.state.message = "Error updating profile. Please try again.";
      this.state.messageType = "error";
    }

    await this.loadUser();
  }

  actionClearMessage() {
    this.state.message = null;
    this.state.messageType = null;
  }
}

const ControlledProfilePage: React.FC = () => {
  const controller = ProfilePageController.use();
  const { saving, userData, message, messageType, canUpdateProfile } = controller.state;
  const bindInput = bindControllerState(controller, "userData");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await controller.actionSubmit();
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    controller.setEmail(e.target.value);
  };

  const renderAuthenticationMessage = () => {
    if (canUpdateProfile) return null;
    return <ExtraAuthComponent />;
  };

  return (
    <DashLayout title="Your Profile">
      <form onSubmit={handleSubmit} className="flex flex-col justify-between h-full pb-6">
        <div>
          {message && messageType && (
            <MessageBanner
              message={message}
              type={messageType}
              onClose={() => controller.actionClearMessage()}
            />
          )}
          {renderAuthenticationMessage()}
          <LabelledField label="Name" htmlFor="name">
            <Input type="text" {...bindInput.bind("name")} disabled={!canUpdateProfile} />
          </LabelledField>
          <LabelledField label="Email">
            <ObfuscatedInput
              // type="email"
              value={userData.email}
              onChange={handleEmailChange}
              disabled={!canUpdateProfile}
              readOnly={!canUpdateProfile}
              name="user_email_do_not_autofill"
              data-1p-ignore
              spellCheck="false"
              autoComplete="off"
            />
          </LabelledField>
          <LabelledField label="Mobile Number">
            <ObfuscatedInput
              // type="tel"
              {...bindInput.bind("mobile")}
              disabled={!canUpdateProfile}
              readOnly={!canUpdateProfile}
            />
          </LabelledField>
          <div className="flex items-center gap-2 p-4 mt-2 border rounded-md border-sky-200 bg-sky-100 text-sky-900">
            <InfoIcon className="w-10 stroke-sky-800" />
            <span>Your email address and mobile number are private and are not shared with your clubs.</span>
          </div>
        </div>
        <div>
          <Button type="submit" className="w-full" spinner={saving} disabled={saving || !canUpdateProfile}>
            Save
          </Button>
        </div>
      </form>
    </DashLayout>
  );
};

const ProfilePage = ProfilePageController.scope(ApplicationView(ControlledProfilePage));
export { ProfilePage };
